<template>
  <div class="flix-form-group">
    <h3 class="flix-html-h3">{{ $t('message.calendarTitle') }}</h3>
    <div class="flix-input-group">
      <span class="flix-input-group-addon">
        <flixIcon v-if="check" class="flix-text-success" :id="'ok'" />
        <flixIcon v-else class="flix-text-warning" :id="'remove'" />
      </span>
      <input type="text" class="flix-form-control" v-model="data.title" :placeholder='title[data.type]'>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: { data: Object, callback: Function, settings: Object },
  data () {
    return {
      check: this.checkTitle(),
      title: {
        datepicker: this.$t('demo.dateTitlePlaceholder'),
        eventpicker: this.$t('demo.eventTitlePlaceholder')
      }
    }
  },
  watch: {
    'data.title' (val) { this.check = false; if (val.trim().length) { this.check = true; this.callback({ title: val }) } }
  },
  methods: {
    getTitle () {
      if (typeof this.settings === 'object' && typeof this.settings.titlePlaceholder === 'string') {
        this.title[this.data.type] = this.settings.titlePlaceholder
      }
    },
    checkTitle () {
      if (this.data.title.trim().length) {
        return true
      }
      return false
    }
  },
  mounted () {
    this.getTitle()
  }
}
</script>
<style lang="sass" scoped>
</style>
